import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.1_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_7n64za4anmzddydt7fgoenepue/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.1_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_7n64za4anmzddydt7fgoenepue/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.1_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_7n64za4anmzddydt7fgoenepue/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.1_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_7n64za4anmzddydt7fgoenepue/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.1_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_7n64za4anmzddydt7fgoenepue/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.1_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_7n64za4anmzddydt7fgoenepue/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.1_@babel+core@7.24.4_@opentelemetry+api@1.9.0_@playwright+test@1.45.0_babel-plugin-_7n64za4anmzddydt7fgoenepue/node_modules/next/dist/lib/metadata/metadata-boundary.js");
